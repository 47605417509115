import service from "@/services/pesquisas.js";
import pesquisas from "../../../services/pesquisas";

const state = {
  pesquisas: []
};
const mutations = {};
const actions = {
  async getPesquisas({state}, payload) {
    try {
      const { data } = await service.get(payload);
      state.pesquisas = data
    } catch (error) {
      console.error(error)
    }
  },
  async getPesquisa({state}, id) {
    try {
      const { data } = await service.getPorId(id);
      state.pesquisas = data
    } catch (error) {
      console.error(error)
    }
  },
  async salvarPesquisa({state, rootState}, payload){
    const {id: empresaId} = rootState['empresa'].empresaSelecionada
    payload.empresa_id = empresaId
    try {
      if (!payload.id){
        const {data} = await service.post(payload)
        return data
      } else {
        const {data} = await service.patch(payload)
        return data
      }
    } catch(e) {
      throw Error(e)
    } 
  }
};
const getters = {
  pesquisas: (state) => state.pesquisas
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
