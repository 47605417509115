/* eslint-disable */
import http from '@/plugins/axios';
import oauth from '../services/oauth'
import enviroment from '@/assets/js/enviroment';
import * as Sentry from '@sentry/browser';

const state = {
  usuario: {
    empresas: [],
  },
  token: null,
}

const mutations = {
  setToken(state, token) {
    console.debug('Salvando o token de autenticação.');

    state.token = token;
    localStorage.setItem('melembreAuthToken', token);
    http.setTokenCoreApi(token);
    http.setTokenDadosApi(token);

    let urlContinue = localStorage.getItem('urlContinue');

    if (urlContinue && urlContinue != 'null') {
      localStorage.setItem('urlContinue', null);
      window.location = urlContinue;
    }
  },
  setUsuario(state, usuario) {
    console.debug('Salvando o usuário autenticado:', usuario.email);

    state.usuario = usuario;
    localStorage.setItem('melembreUsuario', JSON.stringify(usuario));

    Sentry.setUser({
      id: usuario.id,
      email: usuario.email,
      username: usuario.nome,
    });
  },
}

const actions = {
  auth({ commit, dispatch }, token) {
    return new Promise(async (resolve) => {
      if (token) {
        commit('setToken', token);
      } else {
        console.error('Autenticação sem token.');
        irParaQuiteJaAuth();
        return;
      }

      console.debug('Consultando o usuário autenticado.');
      await oauth.me().then(res => {
        console.debug('Usuário autenticado:', res.data.email);
        commit('setUsuario', res.data);
        commit('empresa/setEmpresas', res.data.empresas, { root:true });
        resolve();
      }).catch((erro) => {
        console.error('Não foi possível buscar o usuário autenticado.', erro);
        dispatch('logout');
        irParaQuiteJaAuth();
      });
    });
  },
  logout() {
    console.debug('Deslogando.');

    state.usuario = null;
    state.token = null;

    localStorage.removeItem('melembreAuthToken');
    localStorage.removeItem('melembreUsuario');
  },
  async verificarAutenticacao({ commit, dispatch }) {
    if (state.usuario && state.usuario.id && state.token) {
      return true;
    }

    const token = localStorage.getItem('melembreAuthToken');

    if (token) {
      commit('setToken', token);
      http.setTokenCoreApi(token);
      http.setTokenDadosApi(token);
    } else {
      console.debug('Token de autenticação não encontrado.');
      irParaQuiteJaAuth();
      return;
    }

    console.debug('Consultando o usuário autenticado.');

    await oauth.me().then(res => {
      commit('setUsuario', res.data);
      commit('empresa/setEmpresas', res.data.empresas, { root:true });
    }).catch((erro) => {
      console.error('Não foi possível buscar o usuário autenticado.', erro);

      let resposta = erro.response || {};

      if (resposta.status == 401) {
        dispatch('logout');
        irParaQuiteJaAuth();
        return;
      }

      throw erro;
    });

    return true;
  }
}

const getters = {
  getUsuario({ usuario }) { return usuario }
}

function irParaQuiteJaAuth() {
  window.location = enviroment.coreApiUrl + '/oauth/login';
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
