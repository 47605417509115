import apiEvento from '@/services/evento';
import apiRegua from '@/services/regua';

const state = {
  carregandoEventos: false,
  carregandoRegua: false,

  erroCarregarEventos: false,
  erroCarregarRegua: false,

  eventos: [],
  regua: {},
}

const mutations = {
  setCarregandoEventos: (state, payload) => state.carregandoEventos = payload,
  setCarregandoRegua: (state, payload) => state.carregandoRegua = payload,
  setErroCarregarEventos: (state, payload) => state.erroCarregarEventos = payload,
  setErroCarregarRegua: (state, payload) => state.erroCarregarRegua = payload,
  setEventos: (state, payload) => state.eventos = payload,
  setRegua: (state, payload) => state.regua = payload,
}

const actions = {
  async carregarEventos({ commit }, params) {
    console.debug('Carregando eventos da régua');

    commit('setCarregandoEventos', true);
    commit('setErroCarregarEventos', false);

    try {
      const resposta = await apiEvento.listar(params);
      commit('setEventos', resposta.data.objects);
    } catch (erro) {
      console.error('Não foi possível carregar os eventos da régua', erro);
      commit('setErroCarregarEventos', true);
    }

    commit('setCarregandoEventos', false);
  },

  async carregarRegua({ commit }, id) {
    console.debug('Carregando a Régua', id);

    commit('setCarregandoRegua', true);
    commit('setErroCarregarRegua', false);

    try {
      const resposta = await apiRegua.get(id);
      commit('setRegua', resposta.data);
      return resposta.data
    } catch (erro) {
      console.error('Não foi possível carregar a régua', erro);
      commit('setErroCarregarRegua', true);
    }

    commit('setCarregandoRegua', false);
  }
}

export default {
  state,
  mutations,
  actions,
  namespaced: true,
};
