export default [
  {
    path: "/nps",
    name: "NPS",
    component: () => import(/* webpackChunkName: "console" */ "./pages/NPS.vue"),
    meta: {
      title: "NPS",
    },
  },
];
