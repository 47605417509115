export default [
  {
    path: '/token',
    name: 'Ajuda',
    component: () => import(/* webpackChunkName: "console" */ './pages/Token.vue'),
    meta: {
      title: 'Token',
      precisaAutenticacao: true,
      precisaEmpresa: true,
    },
  },
]
