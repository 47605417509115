export const rotas =  [
  {
    path: '/contratos',
    name: 'Contratos',
    component: () => import(/* webpackChunkName: "console" */ './PesquisaContratos.vue'),
    meta: {
      title: 'Consulta de Contratos',
    },
  },
  {
    path: '/contrato/fake',
    name: 'ContratoFake',
    component: () => import(/* webpackChunkName: "console" */ './FormularioContrato.vue'),
    meta: {
      title: 'Importação de Contrato Fake',
    },
  },
]
