import http from "@/plugins/axios";
import store from '@/store';

export default {
  atualizar(id, payload) {
    let empresa = store.getters['empresa/empresaSelecionada'];
    return http.api.patch(`${empresa.url_instancia}/v1/cliente/${id}`, payload);
  },
  consultaClientes(params) {
    let empresa = store.getters['empresa/empresaSelecionada'];
    return http.api.get(`${empresa.url_instancia}/v1/cliente`, {params})
  },
  getCliente(params, id) {
    let empresa = store.getters['empresa/empresaSelecionada'];
    return http.api.get(`${empresa.url_instancia}/v1/cliente/${id}`, {params})
  }
}
