import UsuariosService from '@/services/usuario'

const state = () => {
  return {
    usuarios: null,
  }
};

const mutations = {
  setUsuarios(state, payload) {
    state.usuarios = payload;
  },
};

const actions = {
  async getUsuarios({ commit, rootState }, payload) {
    try {
      const { id: empresaId } = rootState.empresa?.empresaSelecionada;
      const params = { ...payload, empresa_id: empresaId };
      const result = await UsuariosService.get(params)
    } catch (e) {
      Promise.reject(e)
    }
  }
};

const getters = {
  usuarios: (state) => state.usuarios,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
