export default {
  bind(el, binding) {
    const scrollContainer = document.querySelector('.v-main__wrap')
    const scrollHandler = () => {
      const rect = el.getBoundingClientRect()
      const viewportHeight = scrollContainer.innerHeight || document.documentElement.clientHeight;
      if(rect.bottom <= viewportHeight){
        binding.value()
      }
    };
    scrollContainer.addEventListener('scroll', scrollHandler);
    // Save the handler to remove it later if needed
    window.__vueInfiniteScroll__ = scrollHandler;
  },
  unbind(el) {
    window.removeEventListener('scroll', el.__vueInfiniteScroll__);
    delete window.__vueInfiniteScroll__;
  }
};