const state = {
  resultadoPesquisa: null,
};

const mutations = {
  setResultadoPesquisa(state, payload) {
    state.resultadoPesquisa = payload;
  },
};

const actions = {
  alteraResultadoPesquisa({ commit }, resultado) {
    commit("setResultadoPesquisa", resultado)
  }
};

const getters = {
  resultadoPesquisa: (state) => state.resultadoPesquisa,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
