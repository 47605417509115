import http from "@/plugins/axios";
import store from "@/store";
export default {
  getMovimentacao(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(`${empresa.url_instancia}/v1/movimentacao`, { params });
  },
  cadastraMovimentacao(payload) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.post(`${empresa.url_instancia}/v1/movimentacao`, payload);
  }
};
