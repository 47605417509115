import parcelaService from "../../services/parcela";

const state = {
  parcelas: {
    objects: new Array(),
  },
  carregandoParcelas: false,
  erroParcelas: false,
};
const mutations = {
  setParcelas: (state, payload) => {
    state.parcelas = payload;
  },
  setCarregandoParcelas: (state, payload) => {
    state.carregandoParcelas = payload;
  },
  setErroParcelas: (state, payload) => {
    state.erroParcelas = payload;
  },
};
const actions = {
  async getParcelas({ commit }, payload) {
    commit("setCarregandoParcelas", true);
    await parcelaService
      .get(payload)
      .then((response) => {
        commit("setErroParcelas", false);
        commit("setCarregandoParcelas", false);
        commit("setParcelas", response.data);
      })
      .catch((error) => {
        commit("setErroParcelas", true);
      })
      .finally(() => {
        commit("setCarregandoParcelas", false);
      });
  },
  async pausarParcela({ commit }, payload) {
    const { data } = await parcelaService.pausarParcela(payload);
    return data;
  },
  limparParcelas({ commit }) {
    commit("setParcelas", {
      objects: new Array(),
    });
  },
  async downloadBoleto({ commit }, payload) {
    try {
      const { data } = await parcelaService.downloadBoleto(payload);
      return data;
    } catch (e) {
      console.error(e)
    }
  }
};
const getters = {
  parcelas: (state) => state.parcelas,
  carregandoParcelas: (state) => state.carregandoParcelas,
  erroParcelas: (state) => state.erroParcelas,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
