import service from "@/services/iniciativas.js";

const state = {
  listaIniciativa: [],
  carregando: false,
  carregandoArquivo: false
}
const mutations = {
  setListaIniciativa(state, payload) {
    state.listaIniciativa = payload;
  },
  setCarregando(state, payload) {
    state.carregando = payload;
  },
  setCarregandoArquivo(state, payload) {
    state.carregandoArquivo = payload;
  },
  setItemListaIniciativa(state, payload) {
    let lista = [...state.listaIniciativa];
    let itemIndex = lista.findIndex((item => item.id == payload.id));

    if (itemIndex >= 0) {
      lista[itemIndex] = payload;
    } else {
      lista.push(payload);
    }

    state.listaIniciativa = lista;
  },
}
const actions = {
  async pegarTodas({ commit, dispatch }, parametros) {
    commit('setCarregando', true);

    await service.pegarTodas(parametros)
      .then(({ data }) => {
        commit('setListaIniciativa', data.objects);
      })
      .catch((error) => {
        dispatch("snackbar/showSnackBar", {
          text: `Erro ao retornar iniciativas`,
        }, { root: true });
        return error;
      })
      .finally(() => {
        commit('setCarregando', false);
      });
  },

  async uploadArquivo({ commit, dispatch }, arquivo) {
    commit('setCarregandoArquivo', true)
    try {
      const urlArquivo = await service.uploadArquivo(arquivo)
      return urlArquivo.data.url
    } catch (err) {
      dispatch('snackbar/showSnackBar', { text: `Erro ao carregar arquivo` }, { root: true });
      throw new Error()
    } finally {
      commit('setCarregandoArquivo', false)
    }
  },

  async criar({ dispatch, commit }, iniciativa) {
    commit('setCarregando', true);
    try {
      const resposta = await service.adicionar(iniciativa)
      dispatch('snackbar/showSnackBar', { text: `Sucesso ao criar iniciativa` }, { root: true });
      commit('setItemListaIniciativa', resposta.data);
    } catch (e) {
      dispatch('snackbar/showSnackBar', { text: `Erro ao criar iniciativa` }, { root: true });
    } finally {
      commit('setCarregando', false);
    }
  },

  async pegarUma({ commit, dispatch }, { itemId, parametros }) {
    commit('setCarregando', true);
    await service.pegarUma(itemId, parametros)
      .then(({ data }) => {
        return data;
      })
      .catch((error) => {
        dispatch("snackbar/showSnackBar", {
          text: `Erro ao retornar iniciativa ${itemId}`,
        }, { root: true });
        return error;
      })
      .finally(() => {
        commit('setCarregando', false);
      });
  },
  async editar({ dispatch, commit }, iniciativa) {
    commit('setCarregando', true);
    let { id, ...iniciativaAlterada } = iniciativa;
    return service.editar(id, { ...iniciativaAlterada }).then(resposta => {
      if (resposta) {
        dispatch('snackbar/showSnackBar', {
          text: `Sucesso ao alterar iniciativa`,
        }, { root: true });
        commit('setItemListaIniciativa', resposta.data);
      } else {
        dispatch('snackbar/showSnackBar', {
          text: `Erro ao alterar iniciativa`,
        }, { root: true });
      }
    }).finally(() => {
      commit('setCarregando', false);
    });
  },
  async deletar({ dispatch, commit }, idIniciativa) {
    commit('setCarregando', true);
    return service.deletar(idIniciativa).then(resposta => {
      if (resposta) {
        dispatch('snackbar/showSnackBar', {
          text: `Sucesso ao deleter iniciativa`,
        }, { root: true });
        commit('setItemListaIniciativa', resposta.data);
      } else {
        dispatch('snackbar/showSnackBar', {
          text: `Erro ao alterar iniciativa`,
        }, { root: true });
      }
    }).finally(() => {
      commit('setCarregando', false);
    });
  },
}
const getters = {
  listaIniciativa: (state) => state.listaIniciativa,
  carregando: (state) => state.carregando,
}
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};