import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  listar(params) {
    return http.api.get(VERSION + "/tipo_movimentacao", { params });
  },
  getById(id) {
    return http.api.get(VERSION + `/tipo_movimentacao/${id}`)
  },
  salvar(payload) {
    if (payload.id) {
      let id = payload.id;
      delete payload.id;

      return http.api.patch(VERSION + `/tipo_movimentacao/${id}`, payload);
    } else {
      return http.api.post(VERSION + "/tipo_movimentacao", payload);
    }
  },
  excluir(id) {
    return http.api.delete(VERSION + `/tipo_movimentacao/${id}`);
  },
};
