<template>
  <v-app :class="classeCss">
    <component :is="layout" ref="component">
      <router-view @toggleDrawerMini="toggleDrawerMini"></router-view>
    </component>
    <SnackBar />
  </v-app>
</template>

<script>

import SnackBar from '@/components/snackBar.vue'
import { mapMutations, mapGetters } from "vuex";
import Menu from "@/components/comum/Menu";

export default {
  name: "App",
  components: {
    SnackBar
  },
  created(){
    this.$route.meta.layout = 'BlankLayout'
  },
  async mounted() {
    const empresas = this.usuario.empresas;
    if (empresas.length) {
      this.setEmpresas(empresas);
      console.debug("Empresas retornadas:", empresas.length);
    }
    let themeColor = document.querySelector('meta[name=theme-color]');

    if (themeColor && this.$store.state.enviroment.name == 'staging') {
      themeColor.setAttribute('content', '#f44336');
    }
  },
  methods: {
    ...mapMutations("empresa", {
      setEmpresas: "setEmpresas",
      setEmpresaSelecionada: "setEmpresaSelecionada",
    }),
    toggleDrawerMini(){
      try{
        console.log(this.$refs.component)
      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {
    layout(){
      console.info('layout em uso:', this.$route.meta.layout || 'DefaultLayout')
      return this.$route.meta.layout || 'DefaultLayout'
    },
    ...mapGetters("auth", {
      usuario: "getUsuario",
    }),
    ...mapGetters("empresa", {
      empresas: "empresas",
      empresaSelecionada: "empresaSelecionada",
    }),

    /** Retorna o nome da classe para ser usado no <v-app> */
    classeCss() {
      if (!this.$route || !this.$route.name) {
        return '';
      }

      return 'pagina-' + this.$route.name.toLowerCase();
    }
  },
};
</script>

<style lang="scss">
html{
  overflow: hidden;
}
.v-application.theme--light {
  .v-main {
    background-color: #f9fbfc !important;
  }
}

.logo {
  height: 80px;
  margin-bottom: 16px;
  width: 80px;
}

.ml-carregando {
  display: flex;
  justify-content: center;
  padding-top: 24px;
}
</style>
