import evento from "@/services/evento";
import anexosEventoService from "@/services/evento";

const state = {
  evento: {
    objeto: new Array(),
  },
  carregando: false,
  erro: false,
  selectedEvento: null,

  anexosEvento: [],
  carregandoAnexos: false,
  erroCarregarAnexos: false,
};
const mutations = {
  setEvento(state, payload) {
    state.evento = payload;
  },
  setCarregando(state, payload) {
    state.carregando = payload;
  },
  setErro(state, payload) {
    state.erro = payload;
  },
  setSelectedEvento(state, payload) {
    state.selectedEvento = payload;
  },
  setAnexosEvento(state, payload) {
    state.anexosEvento = payload;
  },
  setCarregandoAnexos(state, payload) {
    state.carregandoAnexos = payload;
  },
  setErroCarregarAnexos(state, payload) {
    state.erroCarregarAnexos = payload;
  },
};
const actions = {
  novoEvento({ commit }, payload) {
    commit("setEvento", payload);
  },
  async getEvento({ commit }, id) {
    commit("setCarregando", true);
    await evento
      .get(id)
      .then((response) => {
        commit("setEvento", response.data);
        commit("setCarregando", false);
        commit("setErro", false);
      })
      .catch((error) => {
        commit("setErro", true);
      });
  },
  salvarEvento({ commit }, payload) {
    return evento.salvar(payload).then((response) => {
      commit("setEvento", response.data);
      return response.data;
    });
  },

  async getAnexosEvento({ commit }, params) {
    commit("setCarregandoAnexos", true);
    try {
      const payload = {
        evento_id: params.id,
        limite: 1000,
        template_email_id: params?.template_email_id || null,
      }
      const payloadMultiplo = {
        evento_id: params.id,
        limite: 1000,
        template_email_id: params?.template_email_multiplo_id || null,
      }

      const { data } = await anexosEventoService.listarAnexosEvento(payload)
      const anexos = data.data ? [...data.data.objects] : []

      commit("setAnexosEvento", anexos);
      commit("setCarregandoAnexos", false);
      commit("setErroCarregarAnexos", false);
    } catch (error) {
      console.error(error);
      commit("setCarregandoAnexos", false);
      commit("setErroCarregarAnexos", true);
    }
  },

  async salvarAnexoEvento({ commit }, payload) {
    const retorno = await anexosEventoService.salvarAnexoEvento(payload);
    return retorno.data;
  },

  async removerAnexoEvento({ commit }, anexoId) {
    let retorno = await anexosEventoService.removeAnexoEvento(anexoId);
    return retorno;
  },
};
const getters = {
  evento: (state) => state.evento,
  carregando: (state) => state.carregando,
  erro: (state) => state.erro,
  selectedEvento: (state) => state.selectedEvento,

  anexosEvento: (state) => state.anexosEvento,
  carregandoAnexos: (state) => state.carregandoAnexos,
  erroCarregarAnexos: (state) => state.erroCarregarAnexos,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
