export default [
  {
    path: '/404',
    alias: '*',
    name: 'PaginaNaoEncontrada',
    component: () => import(/* webpackChunkName: "console" */ './pages/PaginaNaoEncontrada'),
    meta: {
      title: 'Página não encontrada',
      precisaAutenticacao: true,
      precisaEmpresa: false,
    },
  },
  {
    path: '/erro',
    name: 'Erro',
    component: () => import(/* webpackChunkName: "console" */ './pages/Erro'),
    meta: {
      title: 'Erro',
      precisaAutenticacao: false,
      precisaEmpresa: false,
    },
  },
  {
    path: '/acesso-nao-configurado',
    alias: '*',
    name: 'AcessoNaoConfigurado',
    component: () => import(/* webpackChunkName: "console" */ './pages/AcessoNaoConfigurado'),
    meta: {
      title: 'Acesso não configurado',
      precisaAutenticacao: true,
      precisaEmpresa: false,
    },
  },
]
