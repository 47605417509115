import ContratosService from "@/services/contratos";

const state = {
  contratos: {
    objects: [],
  },
  loading: false,
}
const mutations = {
  setContratos: (state, payload) => {
    state.contratos = payload
  },
  setLoading: (state, payload) => {
    state.loading = payload
  },
}
const actions = {
  async getContratos({ commit }, payload){
    const {data} = await ContratosService.search(payload);
    commit('setContratos', data)
  },
  saveContrato({ commit, rootState }, payload) {},

  limparContratos({ commit }) {
    commit("setContratos", {
      objects: [],
    });
  },
}
const getters = {
  contratos: (state) => state.contratos,
  loading: (state) => state.loading
}
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};