import tipoMovimentacaoService from "@/services/tipo-movimentacao";
import regua from "@/services/regua";

const state = {
  tiposMovimentacao: null,
  carregandoTiposMovimentacao: false,
  erroCarregarTiposMovimentacao: false,
};
const mutations = {
  setTiposMovimentacao(state, payload) {
    state.tiposMovimentacao = payload;
  },
  setCarregandoTiposMovimentacao(state, payload) {
    state.carregandoTiposMovimentacao = payload;
  },
  setErroCarregarTiposMovimentacao(state, payload) {
    state.erroCarregarTiposMovimentacao = payload;
  },
};
const actions = {
  async listarTiposMovimentacao({ commit, rootState }, params) {
    commit("setCarregandoTiposMovimentacao", true);
    await tipoMovimentacaoService
      .listar(params)
      .then(async (retorno) => {
        let lista = retorno.data;

        await lista?.objects.forEach(async (item) => {
          item['carregando'] = true;
          var payload = {
            tipo: "MOVIMENTACAO",
            limite: 1000,
            incluir: "eventos,perfis",
            tipo_movimentacao_id: item.id,
            empresa_id: rootState.empresa.empresaSelecionada.id
          };

          const { data } = await regua.listar(payload);
          item['carregando'] = false;
          item.reguas = data.objects || [];
        });

        commit("setTiposMovimentacao", lista);
        commit("setCarregandoTiposMovimentacao", false);
        commit("setErroCarregarTiposMovimentacao", false);
      })
      .catch((error) => {
        commit("setCarregandoTiposMovimentacao", false);
        commit("setErroCarregarTiposMovimentacao", true);
      });
  },
  async salvarTipoMovimentacao({ commit }, payload) {
    const { data } = await tipoMovimentacaoService.salvar(payload);
    return data;
  },
  async excluirTipoMovimentacao({ commit }, id) {
    const { data } = await tipoMovimentacaoService.excluir(id);
    return data;
  },
};
const getters = {
  listaTiposMovimentacao: (state) => state.tiposMovimentacao,
  carregandoTiposMovimentacao: (state) => state.carregandoTiposMovimentacao,
  erroCarregarTiposMovimentacao: (state) => state.erroCarregarTiposMovimentacao,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
