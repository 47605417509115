import movimentacaoService from "@/services/movimentacao.js";
const state = {
  movimentacoes: null,
};
const mutations = {
  setMovimentacoes(state, payload) {
    state.movimentacoes = payload;
  },
};
const actions = {
  async buscaMovimentacoes({ commit }, params) {
    await movimentacaoService.getMovimentacao(params).then((retorno) => {
      commit("setMovimentacoes", retorno.data);
    });
  },
};
const getters = {
  movimentacoes: (state) => state.movimentacoes,
};
export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
