import service from "@/services/emails.js";

const state = {
  emails: [],
  emailSelecionado: null,
  validacao: null,
  page: 1,
  total_pages: 1,
};
const mutations = {
  setEmails(state, payload) {
    state.emails = payload;
  },
};
const actions = {
  async getEmails({ rootState, state }, payload) {
    try {
      const { id: empresaId } = rootState["empresa"].empresaSelecionada;
      payload.empresa_id = empresaId;
      const { data } = await service.get(payload);
      state.emails = data.objects;
      state.page = data.page;
      state.total_pages = data.total_pages;
    } catch (error) {
      console.error(error);
    }
  },
  async getMaisEmails({ rootState, state }, payload) {
    try {
      payload.pagina = state.page + 1;
      const { id: empresaId } = rootState["empresa"].empresaSelecionada;
      payload.empresa_id = empresaId;
      const { data } = await service.get(payload);
      state.emails = [...state.emails, ...data.objects];
      state.page = data.page;
    } catch (error) {
      console.error(error);
    }
  },
  async validarEmail({ state, rootState }, email) {
    try {
      const { id: empresaId } = rootState["empresa"].empresaSelecionada;
      const payload = {
        empresa_id: empresaId,
        assunto: email.assunto,
        conteudo: email.conteudo,
        tipo_regua: email.tipo_regua,
        nome: email.nome,
      }
      if (email.id) payload.id = email.id
      const { data } = await service.validar(payload);
      state.validacao = data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getEmail({ state }, id) {
    try {
      const { data } = await service.getPorId(id);
      state.emailSelecionado = data;
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async salvar({ state, rootState }, { payload, id }) {
    const { id: empresaId } = rootState["empresa"].empresaSelecionada;
    if (!id) payload.empresa_id = empresaId;
    else delete payload.empresa_id;
    try {
      const { data } = await service.salvar(payload, id);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async publicar({ state, rootState }, payload) {
    try {
      const { data } = await service.publicar(payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async arquivarEmail({ state, rootState }, id) {
    try {
      const { data } = await service.arquivar(id);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async restaurar({ state, rootState }, payload) {
    try {
      const { data } = await service.salvar(
        { status: payload.status },
        payload.id
      );
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async despublicar({ state, rootState }, payload) {
    try {
      const { data } = await service.despublicar(payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async salvarAnexo({}, payload) {
    try {
      const { data } = await service.salvarAnexo(payload);
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async pegarAnexo({}, templateEmailId) {
    if (!templateEmailId) return Promise.resolve();
    try {
      const { data } = await service.getAnexo(templateEmailId);
      return data.objects;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async removeAnexo({}, anexoId) {
    if (!anexoId) return Promise.resolve();
    try {
      const { data } = await service.removeAnexo(anexoId);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};
const getters = {
  emails: (state) => state.emails,
  page: (state) => state.page,
  total_pages: (state) => state.total_pages,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
