import { rotas as ajuda } from '@/views/ajuda';
import { rotas as contrato } from '@/views/contrato';
import { rotas as dashboard } from '@/views/dashboard';
import { rotas as erro } from '@/views/erro';
import { rotas as info } from '@/views/info';
import { rotas as reguas } from '@/views/reguas';
import { rotas as nps } from '@/views/nps';
import { rotas as iniciativas } from '@/views/iniciativas';
import { rotas as contratos } from '@/views/contratos/rotas';
import { rotas as agenda } from '@/views/agenda/rotas';
import { rotas as fatura } from '@/views/fatura/rotas';
import { rotas as pesquisas } from '@/views/pesquisas';
import { rotas as emails } from '@/views/emails';
import { rotas as configs } from '@/views/configuracoes/rotas';

export default [
  ...ajuda,
  ...contrato,
  ...dashboard,
  ...erro,
  ...info,
  ...reguas,
  ...nps,
  ...iniciativas, 
  ...contratos,
  ...agenda,
  ...fatura,
  ...pesquisas,
  ...emails,
  ...configs
];
