<template>
  <v-menu v-if="usuario" bottom min-width="200px" rounded offset-y>
    <template v-slot:activator="{ on }">
      <v-btn icon x-large v-on="on">
        <v-avatar v-if="usuario.url_foto" size="40">
          <img :src="usuario.url_foto" :alt="usuario.nome" />
        </v-avatar>
        <v-avatar color="primary" v-else>
          <v-icon color="white" style="font-size: 26px"
            >mdi-account-circle</v-icon
          >
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center pb-0">
        <div class="mx-auto text-center">
          <div class="pa-4 pt-2">
            <div class="text-h6 font-weight-medium">{{ usuario.nome }}</div>
            <div class="text-body-2 text--secondary">
              {{ usuario.email }}
            </div>
          </div>

          <v-list class="pa-0">
            <v-divider></v-divider>

            <v-list-item :href="authUrl + '/my-apps'" target="_blank">
              <v-list-item-title>Meus Apps</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item @click="logoutAppBar">
              <v-list-item-title>Sair</v-list-item-title>
            </v-list-item>
          </v-list>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
</template>

<script>
import environment from "@/assets/js/enviroment";
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    authUrl: environment.authUrl,
  }),
  computed: {
    ...mapGetters("auth", {
      usuario: "getUsuario",
    }),
  },
  methods: {
    ...mapActions("auth", {
      logout: "logout",
    }),

    logoutAppBar() {
      this.logout().then(() => {
        window.location.href = this.authUrl;
      });
    },
  },
};
</script>

<style scoped lang="scss"></style>
