const host = window.location.hostname;
const configs = {
  localhost: {
    authUrl: 'http://localhost:9292',
    coreApiUrl: 'http://localhost:5800',
    landingPageUrl: 'http://localhost:5910',
    name: "development",
    quitejaBackofficeUrl: "http://localhost:8283/#!",
    quitejaEmpresaId: 1,
    converterUrl: "http://localhost:5920",
    dadosUrl: "http://localhost:5802",
    autosservicoUrl: "http://localhost:5912",
  },
  "console.melembre.gorillascode.com": {
    authUrl: "https://auth.staging.gorillascode.com",
    coreApiUrl: "https://api.melembre.gorillascode.com",
    landingPageUrl: "https://melembre.gorillascode.com",
    name: "staging",
    quitejaBackofficeUrl: "https://backoffice.quiteja.staging.gorillascode.com",
    quitejaEmpresaId: 7,
    converterUrl: "https://converter.staging.gorillascode.com",
    dadosUrl: "https://dados.melembre.gorillascode.com",
    autosservicoUrl: "https://app.melembre.gorillascode.com",
  },
  "console.melembre.com.br": {
    authUrl: "https://auth.quiteja.com.br",
    coreApiUrl: "https://api.melembre.com.br",
    landingPageUrl: "https://melembre.com.br",
    name: "production",
    quitejaBackofficeUrl: "https://backoffice.quiteja.com.br",
    quitejaEmpresaId: 6,
    converterUrl: "https://converter.gorillascode.com",
    dadosUrl: "https://dados.melembre.com.br",
    autosservicoUrl: "https://app.melembre.com.br",
  },
};

let env = {};
if (host.includes("pr-")) {
  env = {
    authUrl: "https://auth.staging.gorillascode.com",
    coreApiUrl: "https://api.melembre.gorillascode.com",
    landingPageUrl: "https://melembre.gorillascode.com",
    name: "staging",
    quitejaBackofficeUrl: "https://backoffice.quiteja.staging.gorillascode.com",
    quitejaEmpresaId: 7,
    converterUrl: "https://converter.staging.gorillascode.com",
    dadosUrl: "https://dados.melembre.gorillascode.com",
    autosservicoUrl: "https://app.melembre.gorillascode.com",
  };
} else {
  env = {
    /** Todas as configurações de todos os ambientes. */
    configs: configs,

    /** URL do QuiteJá Auth. */
    authUrl: configs[host].authUrl,

    /** URL do melembre-core-api */
    coreApiUrl: configs[host].coreApiUrl,

    /** URL do melembre-landing-page */
    landingPageUrl: configs[host].landingPageUrl,

    /** URL do Backoffice da QuiteJá */
    quitejaBackofficeUrl: configs[host].quitejaBackofficeUrl,

    /** Id da empresa "QuiteJá" */
    quitejaEmpresaId: configs[host].quitejaEmpresaId,

    dadosUrl: configs[host].dadosUrl,

    autosservicoUrl: configs[host].autosservicoUrl,

    /** Nome do ambiente onde a aplicação está rodando. */
    name: configs[host].name,
  };
}
export default env;
