import service from "@/services/variavel-mensagem.js";

const state = {
    carregando: false,
    variaveisPreDefinidas: [],
    variaveisDinamicas: [],
    variaveis: [],
    fecharDialog: false
}
const mutations = {
    setVariaveis(state, payload) {
        state.variaveis = payload;
        state.variaveisPreDefinidas = payload.filter(variavel => variavel?.pre_definida == true);
        state.variaveisDinamicas = payload.filter(variavel => !variavel?.pre_definida);
    },
    adicionarVariavel(state, payload) {
        state.variaveisDinamicas = [...state.variaveisDinamicas, payload];
    },
    atualizarVariavel(state, payload) {
        let novaLista = [...state.variaveisDinamicas];
        let posicaoVariavel = novaLista.findIndex((variavel => variavel.id == payload.id));
        novaLista[posicaoVariavel] = payload;

        state.variaveisDinamicas = novaLista;
    },
    excluirVariavel(state, payload) {
        state.variaveisDinamicas = state.variaveisDinamicas.filter(variavel => variavel.id != payload.id);
    },
    setCarregando(state, payload) {
        state.carregando = payload;
    },
}
const actions = {
    async pegar({ commit, dispatch }, parametros) {
        commit('setCarregando', true);
        await service.listar(parametros)
            .then(({ data }) => {
                commit('setVariaveis', data);
            })
            .catch((error) => {
                console.log(error);
                dispatch("snackbar/showSnackBar", {
                    text: `Erro ao atualizar informações`,
                }, { root: true });
                return error;
            })
            .finally(() => {
                commit('setCarregando', false);
            });
    },
    async criar({ dispatch, commit }, variavel) {
        delete variavel.id;
        variavel.referencia = variavel.referencia.replace(/["']/g, "'");
        variavel.ativa = true;
        if (!variavel?.tamanho) variavel.tamanho = null;

        let index = state.variaveisDinamicas.findIndex((variavelBanco) => variavelBanco.nome == variavel.nome)

        if (index < 0){
            return service.adicionarVariavelMensagem(variavel).then(resposta => {
                if (resposta) {
                    dispatch('snackbar/showSnackBar', {
                        text: `Sucesso ao criar variável`,
                    }, { root: true });
                    commit('adicionarVariavel', resposta.data);
                } else {
                    dispatch('snackbar/showSnackBar', {
                        text: `Erro ao criar variável`,
                    }, { root: true });
                }
                state.fecharDialog = true;
            });
        } else {
            dispatch('snackbar/showSnackBar', {
                text: `Já existe uma variável com o nome indicado.`,
            }, { root: true });
            state.fecharDialog = false;
        }
    },
    async editar({ dispatch, commit }, variavel) {
        if (variavel?.referencia) {
            variavel.referencia = variavel.referencia.replace(/["']/g, "'");
        } else {
            variavel.referencia = "";
        }
        if (!variavel?.tamanho) variavel.tamanho = null;

        let index = state.variaveisDinamicas.findIndex((variavelBanco) => variavelBanco.nome == variavel.nome)
        if (index >= 0 && variavel.id){
            if(state.variaveisDinamicas[index]['id'] == variavel.id) {
                return service.salvarVariavelMensagem(variavel).then(resposta => {
                    if (resposta) {
                        dispatch('snackbar/showSnackBar', {
                            text: `Sucesso ao alterar variável`,
                        }, { root: true });
                        commit('atualizarVariavel', resposta.data);
                    } else {
                        dispatch('snackbar/showSnackBar', {
                            text: `Erro ao alterar variável`,
                        }, { root: true });
                    }
                    state.fecharDialog = true;
                });
            } else {
                dispatch('snackbar/showSnackBar', {
                    text: `Já existe uma variável com o nome indicado.`,
                }, { root: true });
                state.fecharDialog = false;
            }
        } else if(index < 0 && variavel.id) {
            return service.salvarVariavelMensagem(variavel).then(resposta => {
                if (resposta) {
                    dispatch('snackbar/showSnackBar', {
                        text: `Sucesso ao alterar variável`,
                    }, { root: true });
                    commit('atualizarVariavel', resposta.data);
                } else {
                    dispatch('snackbar/showSnackBar', {
                        text: `Erro ao alterar variável`,
                    }, { root: true });
                }
                state.fecharDialog = true;
            });
        }
    },
    async excluir({ dispatch, commit }, variavel) {
        return service.excluirVariavelMensagem(variavel).then(resposta => {
            if (resposta) {
                dispatch('snackbar/showSnackBar', {
                    text: `Sucesso ao excluir variável`,
                }, { root: true });
                commit('excluirVariavel', resposta.data);
            } else {
                dispatch('snackbar/showSnackBar', {
                    text: `Erro ao excluir variável`,
                }, { root: true });
            }
        });
    },
}
const getters = {
    variaveis: (state) => state.variaveis,
    variaveisPreDefinidas: (state) => state.variaveisPreDefinidas,
    variaveisDinamicas: (state) => state.variaveisDinamicas,
    carregando: (state) => state.carregando,
    fecharDialog: (state) => state.fecharDialog,
}
export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true,
};