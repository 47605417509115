import http from "@/plugins/axios";

export default {
  getNps(params) {
    return http.api.get(`/v1/nps`, { params });
  },
  adicionarItem(payload) {
    return http.api.post(`/v1/nps`, payload);
  },
  editarItem(payload) {
    return http.api.patch(`/v1/nps`, payload);
  },
  buscarEventosVinculados(id) {
    return http.api.get(`/v1/nps/${id}/eventos`, {
      validateStatus: function (status) {
        return status < 400;
      }
    });
  },
};
