export default [
  {
    path: '/dashboard/carteira',
    name: 'DashboardCarteira',
    component: () => import(/* webpackChunkName: "console" */ './carteira/Index'),
    meta: {
      title: 'Dashboard Carteira',
      precisaEmpresa: true,
    },
  },
  {
    path: '/dashboard/:id*',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "console" */ './pages/Dashboard'),
    meta: {
      title: 'Dashboard',
      precisaEmpresa: true,
    },
  },
]
