import FaqService from "@/services/faq";

const state = () => {
  return {
    faqs: [],
    paginator: {
      num_results: 0,
      page: 1,
      total_pages: 1,
    },
  };
};

const mutations = {
  setFaqs(state, payload) {
    state.faqs = payload;
  },
  unshiftFaq(state, payload){
    state.faqs.unshift(payload)
  },
  alteraFaq(state, payload){
    const index = state.faqs.findIndex((faq) => {
      return faq.id == payload.id
    })
    if(index === -1) return
    state.faqs.splice(index, 1, payload)
  },
  removeFaq(state, payload){
    const index = state.faqs.findIndex((faq) => {
      return faq.id == payload.id
    })
    if(index === -1) return
    state.faqs.splice(index, 1)
  },
  setPaginator(state, payload) {
    delete payload.data;
    state.paginator = payload;
  },
};

const actions = {
  async getFaqs({ commit, rootState }, payload) {
    try {
      const { id: empresaId } = rootState.empresa?.empresaSelecionada;
      const params = { ...payload, empresa_id: empresaId };
      const { data } = await FaqService.get(params);
      commit("setFaqs", data.objects);
      commit("setPaginator", data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async ordenarFaqs({ rootState }, payload) {
    try {
      const params = { ...payload };
      const { data } = await FaqService.ordenar(params);
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async criaFaq({ rootState, commit }, payload) {
    try {
      const params = { ...payload };
      const { data } = await FaqService.post(params);
      commit('unshiftFaq', data)
      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
  async alteraFaq({ rootState, commit }, payload) {
    try {
      const params = { ...payload };
      const { data } = await FaqService.patch(params);
      commit('alteraFaq', data)
    } catch (error) {
      return Promise.reject(error)
    }
  },
  async deletaFaq({ rootState, commit }, payload) {
    try {
      const params = { ...payload };
      const { data } = await FaqService.delete(params.id);
      commit('removeFaq', payload)
    } catch (error) {
      return Promise.reject(error)
    }
  },
};

const getters = {
  faqs: (state) => state.faqs,
  paginator: (state) => state.paginator,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
