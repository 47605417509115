import http from "@/plugins/axios";
import store from "@/store";

export default {
  get(id) {
    return http.api.get(`/v1/regua/${id}`);
  },
  listar(params) {
    return http.api.get("/v1/regua", { params });
  },

  resumoContratos(params) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(`${empresa.url_instancia}/v1/perfil/tabela`, {
      params,
    });
  },

  criaTesteAB(teste_ab) {
    return http.api.post("/v1/regua/teste_ab", { teste_ab });
  },

  save(payload) {
    if (payload.id) {
      let id = payload.id;
      delete payload.id;

      return http.api.patch(`/v1/regua/${id}`, payload);
    } else {
      return http.api.post("/v1/regua", payload);
    }
  },
  vincularReguaPerfil(payload) {
    return http.api.post("/v1/regua/perfil_regua", payload);
  },
  editaReguaPerfil(payload) {
    let regua_id = payload.regua_atual;
    delete payload.regua_atual;
    return http.api.put(`/v1/regua/${regua_id}/perfil_regua`, payload);
  },
  desvincularPerfil(payload) {
    return http.api.delete(`/v1/regua/perfil_regua`, { data: payload });
  },
};
